import React, { createRef } from "react";
import Header from "~/components/header";
import Layout from "~/components/layout";
import Reports from "~/components/reports";

const IndexPage = ({ location }) => {
	const ref = createRef();
	return (
		<Layout data-location={location} pageTitle={'Ideas Report Hub | WeTransfer'}>
			<Header ref={ref} />
			<Reports headerRef={ref} />
		</Layout>
	);
};

export default IndexPage;
